import { BiUserCircle } from "react-icons/bi";
import { FiPhone } from "react-icons/fi";
import { HiOutlineUserGroup } from "react-icons/hi";
import { HiOutlineBuildingOffice } from "react-icons/hi2";
import { IoLocationOutline, IoMailOutline } from "react-icons/io5";
import { PiUserCircleGear } from "react-icons/pi";

export const Icons = {
  companyIcon: <HiOutlineBuildingOffice />,
  mailIcon: <IoMailOutline />,
  phoneIcon: <FiPhone />,
  departmentIcon: <HiOutlineUserGroup />,
  designationIcon: <PiUserCircleGear />,
  locationIcon: <IoLocationOutline />,
  userIcon: <BiUserCircle />,
};
