import {
  AiOutlineFileExcel,
  AiOutlineFilePdf,
  AiOutlineFilePpt,
  AiOutlineFileWord,
} from "react-icons/ai";

const RenderFileIcon = (fileType) => {
  switch (fileType) {
    case "pdf":
      return <AiOutlineFilePdf className="text-red-500 text-5xl" />;
    case "msword":
    case "vnd.openxmlformats-officedocument.wordprocessingml.document":
      return <AiOutlineFileWord className="text-blue-500 text-5xl" />;
    case "vnd.ms-excel":
    case "xls":
    case "xlsx":
    case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return <AiOutlineFileExcel className="text-green-500 text-5xl" />;
    case "vnd.ms-powerpoint":
    case "vnd.openxmlformats-officedocument.presentationml.presentation":
      return <AiOutlineFilePpt className="text-orange-500 text-5xl" />;
    default:
      return <p className="text-gray-400">Preview not available</p>;
  }
};

export default RenderFileIcon;
