import React, { useContext, useEffect, useState } from "react";
import { CustomButton, CustomInput, CustomSelect } from "../../common";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { MdKeyboardArrowDown, MdOutlineEventNote } from "react-icons/md";
import {
  priorityOptions,
  Roles,
  statusOptions,
} from "../../../constant/Constant";
import { subTaskEndPoints } from "../../../networking/Endpoints";
import { setLoading } from "../../../redux/slice/authSlice";
import { apiConnector } from "../../../networking/ApiConnector";
import { ApiError } from "../../../helper/ApiError";
import { toast } from "react-toastify";
import { CustomAlertContext } from "../../../context";
const baseUrl = process.env.REACT_APP_BASE_URL;
const subTaskValidation = yup.object().shape({
  title: yup.string().required("Title is required"),
  assign_to: yup
    .array()
    .of(yup.string().required("Each member must be a valid string"))
    .min(1, "Assign To is required")
    .required("Assign To is required"),
  status: yup.string().notRequired(),
  estimated_time: yup
    .string()
    .matches(
      /^(?:\d{2}|\d{3}):([0-5]\d)$/,
      "Estimated Time must be in the format HH:MM"
    )
    .required("Estimated Time is required"),
  hours_logged: yup
    .string()
    .matches(
      /^(?:\d{2}|\d{3}):([0-5]\d)$/,
      "Hours Logged must be in the format HH:MM"
    )
    .when("status", {
      is: (status) => {
        return status === "Completed";
      },
      then: (schema) => schema.required("Hours Logged is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  completed_on: yup.string().when("status", {
    is: (status) => {
      return status === "Completed";
    },
    then: (schema) => schema.required("Completed_on is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  completed_by: yup
    .array()
    .of(yup.string().required("Each member must be a valid string"))
    .when("status", {
      is: (status) => {
        return status === "Completed";
      },
      then: (schema) =>
        schema
          .min(1, "Completed_by is required")
          .required("Completed_by is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
});
const AddSubTask = ({
  taskId = "",
  start_date = "",
  due_date = "",
  subTask = null,
  setSubTask,
  setOpenAddSubTaskForm,
  taskMembers = [],
}) => {
  const { token, loading, user } = useSelector((state) => state.auth);
  const { setToastNotification } = useContext(CustomAlertContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isEditMode, setIsEditMode] = useState(false);
  const [showOtherDetails, setShowOtherDetails] = useState(false);

  useEffect(() => {
    if (subTask) {
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
  }, [subTask]);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(subTaskValidation),
    defaultValues: {
      title: subTask?.title ? subTask?.title : undefined,
      start_date: subTask?.start_date
        ? subTask?.start_date?.split("T")[0]
        : undefined,
      due_date: subTask?.due_date
        ? subTask?.due_date?.split("T")[0]
        : undefined,
      status: subTask?.status ? subTask?.status : undefined,
      priority: subTask?.priority ? subTask?.priority : undefined,
      assign_to: subTask?.assign_to?.map((item) => item?._id) ?? [],
      description: subTask?.description ? subTask?.description : undefined,
      estimated_time: subTask?.estimated_time
        ? subTask?.estimated_time
        : undefined,
      hours_logged: subTask?.hours_logged ? subTask?.hours_logged : undefined,
      completed_by: subTask?.completed_by ? subTask?.completed_by : undefined,
      completed_on: subTask?.completed_on
        ? subTask?.completed_on?.split("T")[0]
        : undefined,
    },
  });
  const assignToOptions =
    user?.roleId === Roles?.admin || user?.roleId === Roles?.project_manager
      ? taskMembers?.map((member) => ({
          name: member?._id === user?._id ? "You" : member?.user_name,
          value: member?._id,
          designation: member?.designation?.designation,
          avatar: member?.avatar
            ? `${baseUrl}/${member?.avatar}`
            : `https://api.dicebear.com/5.x/initials/svg?seed=${member?.user_name}`,
        }))
      : taskMembers
          ?.filter((member) => member?._id === user?._id)
          ?.map((member) => ({
            name: member?._id === user?._id ? "You" : member?.user_name,
            value: member?._id,
            designation: member?.designation?.designation,
            avatar: member?.avatar
              ? `${baseUrl}/${member?.avatar}`
              : `https://api.dicebear.com/5.x/initials/svg?seed=${member?.user_name}`,
          }));
  const selectedStatus = watch("status");
  const subtask_assignees = watch("assign_to");
  const selectedSubtaskStartdate = watch("start_date");
  const selectedSubtaskEndDate = watch("due_date");

  const completed_by_options = assignToOptions?.filter((item) => {
    if (subtask_assignees.includes(item?.value)) {
      return {
        name: item?.name,
        value: item?.value,
        designation: item?.designation?.designation,
        avatar: item?.avatar
          ? `${baseUrl}/${item?.avatar}`
          : `https://api.dicebear.com/5.x/initials/svg?seed=${item?.user_name}`,
      };
    }
  });
  const onSubmitHandler = async (data) => {
    let payload = isEditMode
      ? Object.keys(data).reduce((acc, key) => {
          if (key === "assign_to" || key === "completed_by") {
            // Deep comparison for assign_to
            const currentAssignTo = data[key].sort();
            const previousAssignTo = subTask[key]
              ?.map((item) => item?._id)
              .sort();

            if (
              JSON.stringify(currentAssignTo) !==
              JSON.stringify(previousAssignTo)
            ) {
              acc[key] = data[key];
            }
          } else if (key === "project") {
            // Shallow comparison for project
            if (data[key] !== subTask[key]?._id) {
              acc[key] = data[key];
            }
          } else if (
            key === "start_date" ||
            key === "due_date" ||
            key === "completed_on"
          ) {
            // Normalize date comparison by converting to ISO string
            const dataDate = data[key]
              ? new Date(data[key]).toISOString()
              : null;
            const prevDate = subTask[key]
              ? new Date(subTask[key]).toISOString()
              : null;

            if (dataDate !== prevDate) {
              acc[key] = data[key];
            }
          } else if (key === "estimated_hrs" || key === "estimated_min") {
            // Combine estimated_time
            const estimatedTime = `${data.estimated_hrs ?? "0"}:${
              data.estimated_min ?? "0"
            }`;
            const prevEstimatedTime = subTask?.estimated_time
              ? subTask?.estimated_time.split(":").join(":")
              : "0:0";

            if (estimatedTime !== prevEstimatedTime) {
              acc["estimated_time"] = estimatedTime;
            }
          } else if (key === "hours_logged_hrs" || key === "hours_logged_min") {
            // Combine hours_logged
            const hoursLogged = `${data.hours_logged_hrs ?? "0"}:${
              data.hours_logged_min ?? "0"
            }`;
            const prevHoursLogged = subTask?.hours_logged
              ? subTask?.hours_logged.split(":").join(":")
              : "0:0";

            if (hoursLogged !== prevHoursLogged) {
              acc["hours_logged"] = hoursLogged;
            }
          } else if (data[key] !== subTask[key]) {
            // Shallow comparison for other fields
            acc[key] = data[key];
          }
          return acc;
        }, {})
      : {
          title: data?.title ? data?.title : undefined,
          start_date: data?.start_date ? data?.start_date : undefined,
          due_date: data?.due_date ? data?.due_date : undefined,
          status: data?.status ? data?.status : undefined,
          priority: data?.priority ? data?.priority : undefined,
          description: data?.description ? data?.description : undefined,
          assign_to: data?.assign_to ? data?.assign_to : undefined,
          estimated_time: data?.estimated_time
            ? data?.estimated_time
            : undefined,
          hours_logged: data?.hours_logged ? data?.hours_logged : undefined,
          completed_by: data?.completed_by ? data?.completed_by : undefined,
          completed_on: data?.completed_on ? data?.completed_on : undefined,
          parent_task: taskId,
        };
    dispatch(setLoading(true));
    const apiMethod = isEditMode ? "PUT" : "POST";
    const apiUrl = isEditMode
      ? `${subTaskEndPoints?.SUBTASK_API}/${subTask?._id}`
      : subTaskEndPoints?.SUBTASK_API;
    try {
      const subtask_response = await apiConnector(apiMethod, apiUrl, payload, {
        Authorization: `Bearer ${token}`,
      });
      toast.success(subtask_response?.data?.message);
      navigate(-1);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };
  return (
    <div className="w-full bg-custom-white rounded-md">
      <form noValidate onSubmit={handleSubmit(onSubmitHandler)}>
        <div className="text-xl font-semibold text-custom-black">
          {isEditMode ? "Edit SubTask" : "Add SubTask"}
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 sm:gap-5">
          <div className="my-2 sm:my-4">
            <CustomInput
              name="title"
              label="SubTask Name"
              inputType="text"
              placeholder=""
              control={control}
              required={true}
              error={errors?.title?.message}
            />
          </div>

          <div className="my-2 sm:my-4 ">
            <CustomInput
              name="start_date"
              label="Start Date"
              inputType="date"
              placeholder=""
              control={control}
              required={true}
              max={due_date}
              min={start_date}
              error={errors?.start_date?.message}
            />
          </div>
          <div className="my-2 sm:my-4 ">
            <CustomInput
              name="due_date"
              label="Due Date"
              inputType="date"
              placeholder=""
              max={due_date}
              min={start_date}
              control={control}
            />
          </div>

          <div className="my-2 sm:my-4">
            <CustomSelect
              name="status"
              label="Status"
              inputType="text"
              placeholder="Select..."
              control={control}
              options={statusOptions}
            />
          </div>
          <div className="my-2 sm:my-4 ">
            <CustomSelect
              name="priority"
              label="Priority"
              inputType="text"
              placeholder="Select..."
              control={control}
              options={priorityOptions}
            />
          </div>
          <div className="my-2 sm:my-4 ">
            <CustomInput
              name="estimated_time"
              label="Estimated Time"
              inputType="text"
              placeholder="00:00"
              control={control}
              required={true}
              error={errors?.estimated_time?.message}
            />
          </div>
          <div className="my-2 sm:my-4">
            <CustomSelect
              name="assign_to"
              label="Assign To"
              inputType="text"
              placeholder="Select..."
              control={control}
              required={true}
              multiple="true"
              options={assignToOptions}
              error={errors?.assign_to?.message}
            />
          </div>
        </div>
        <div className="my-2 sm:my-4">
          <CustomInput
            name="description"
            label="Description"
            inputType="text-area"
            placeholder=""
            control={control}
          />
        </div>
        {(isEditMode || selectedStatus === "Completed") && (
          <div
            onClick={(e) => e.stopPropagation()}
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 sm:gap-5"
          >
            <div className="my-2 sm:my-4">
              <CustomInput
                name="hours_logged"
                label="Hours Logged"
                inputType="text"
                placeholder="00:00"
                control={control}
                required={selectedStatus === "Completed" ? true : false}
                error={errors?.hours_logged?.message}
              />
            </div>
            <div className="my-2 sm:my-4 ">
              <CustomSelect
                name="completed_by"
                label="Completed By"
                inputType="text"
                placeholder="Select..."
                control={control}
                required={selectedStatus === "Completed" ? true : false}
                multiple={true}
                options={completed_by_options}
                error={errors?.completed_by?.message}
                classname="bg-custom-white"
              />
            </div>
            <div className="my-2 sm:my-4 ">
              <CustomInput
                name="completed_on"
                label="Completed On"
                inputType="date"
                placeholder="Select..."
                required={selectedStatus === "Completed" ? true : false}
                error={errors?.completed_on?.message}
                control={control}
                min={selectedSubtaskStartdate}
              />
            </div>
          </div>
        )}
        <div className="flex justify-end items-center my-10 gap-4">
          <CustomButton
            title={"Cancel"}
            buttonType="button"
            onClick={() => {
              setSubTask(null);
              reset();
              setOpenAddSubTaskForm(false);
            }}
            classname="border text-custom-black px-5 py-1 rounded-md"
          />

          <CustomButton
            title={
              loading ? (
                <svg
                  aria-hidden="true"
                  className="w-4 h-4 me-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              ) : (
                "Save"
              )
            }
            disabled={loading}
            buttonType="submit"
            classname={`bg-green-500 font-semibold text-custom-white px-5 py-1.5 rounded-md ${
              loading ? "cursor-not-allowed" : "cursor-pointer"
            }`}
          />
        </div>
      </form>
    </div>
  );
};

export default AddSubTask;
