import React from "react";
import { useSelector } from "react-redux";
import { Roles } from "../../constant/Constant";
import { useNavigate } from "react-router-dom";
import { dateFormatter } from "../../helper/formatDate";
import { MdOutlineEdit } from "react-icons/md";

const baseUrl = process.env.REACT_APP_BASE_URL;

const EmployeeCard = ({ employee }) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  return (
    <div
      onClick={() => {
        if (user?.roleId !== Roles?.employee)
          navigate("/employees/view", { state: { id: employee?._id } });
      }}
      className="p-4 cursor-pointer border border-[#E3F3FF] hover:bg-gray-100 rounded-md space-y-2 shadow-[2px_3px_10px_6px_#257CBB0F]"
    >
      <div className="flex justify-between items-center">
        <div className="flex justify-start items-center gap-4">
          <img
            src={
              employee?.avatar
                ? `${baseUrl}/${employee?.avatar}`
                : `https://api.dicebear.com/5.x/initials/svg?seed=${employee?.user_name}`
            }
            alt={employee?.user_name[0]}
            className="w-8 md:w-11 h-8 md:h-11 rounded-full"
          />
          <div>
            <span className="block font-semibold">{employee?.user_name}</span>
            <span className="block text-xs text-slate-400">
              {employee?.designation?.designation}
            </span>
          </div>
        </div>
        {(user?.roleId === Roles?.admin || user?.roleId === Roles?.hr) &&
          employee?.roleId !== Roles?.admin && (
            <div
              onClick={(event) => {
                event.stopPropagation();
                navigate("/employees/create", {
                  state: { employee: employee },
                });
              }}
              className="p-1 border border-custom-blue text-custom-blue hover:text-custom-white hover:bg-custom-blue  hover:border-custom-white rounded-md cursor-pointer"
            >
              <MdOutlineEdit size={16} />
            </div>
          )}
      </div>
      <div className="w-full h-[1px] border border-dashed" />
      <div className="space-y-2 text-sm">
        <EmployeeText label={"Employee ID"} value={employee?.emp_id} />
        <EmployeeText label={"Name"} value={employee?.user_name} />
        <EmployeeText label={"Email"} value={employee?.email} />
        <EmployeeText label={"Mobile Number"} value={employee?.mobile_number} />
        <EmployeeText label={"Last Login"} value={employee?.last_login} />
      </div>
    </div>
  );
};
const EmployeeText = ({ label, value }) => {
  return (
    <div className="w-full flex justify-between items-center">
      <span className="text-[#696969]">{label}</span>
      <span
        className={`text-[#363636] font-semibold ${
          label === "Email" ? "employee-ellipsis" : ""
        }`}
      >
        {label === "Last Login"
          ? value
            ? dateFormatter(value, {
                format: "MMM D, YYYY hh:mm A",
                includeTimeForToday: true,
              })
            : "--"
          : value}
      </span>
    </div>
  );
};
export default EmployeeCard;
